import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ItemsProvider } from './contexts/ItemsContext';
import { AddressProvider } from './contexts/AddressContext';
import {  AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Auth/Login';
import Shop from './components/User/Shop';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import ProductPage from './components/User/ProductPage';
import CheckoutPage from './components/User/CheckoutPage'
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminItemsDashboard from './components/Admin/AdminItemsDashboard';
import AdminOrdersDashboard from './components/Admin/AdminOrdersDashboard';
import AdminBillsDashboard from './components/Admin/AdminBillsDashboard';
import SuperAdminDashboard from './components/SuperAdmin/SuperAdminDashboard';
import SuperAdminSystemDashboard from './components/SuperAdmin/SuperAdminSystemDashboard';
import ProfilePage from './components/User/ProfilePage';
import Cart from './components/User/Cart';
import ThankYou from './components/User/ThankYou';
import Help from './components/User/Help';
import BuyNow from './components/User/BuyNow';
import TierPage from './components/User/TierPage';
import ResetPassword from './components/Auth/ResetPassword';
import './App.css';

function ProtectedRoutes() {
  return (
      <ItemsProvider>
          <AddressProvider>
              <Routes>
                  <Route path="/user/shop" element={<Shop />} />
                  <Route path="/user/profile" element={<ProfilePage />} />
                  <Route path="/user/cart" element={<Cart />} />
                  <Route path="/product/:id" element={<ProductPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/buy-now" element={<BuyNow />} />
                  <Route path="/thank-you" element={<ThankYou />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/tier-page" element={<TierPage />} />
                  <Route path="/admin-dashboard" element={<AdminDashboard />} />
                  <Route path="/admin-items" element={<AdminItemsDashboard />} />
                  <Route path="/admin-orders" element={<AdminOrdersDashboard />} />
                  <Route path="/admin-bills" element={<AdminBillsDashboard />} />
                  <Route path="/superadmin-dashboard" element={<SuperAdminDashboard />} />
                  <Route path="/superadmin-items" element={<AdminItemsDashboard />} />
                  <Route path="/superadmin-orders" element={<AdminOrdersDashboard />} />
                  <Route path="/superadmin-bills" element={<AdminBillsDashboard />} />
                  <Route path="/superadmin-system" element={<SuperAdminSystemDashboard />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
              </Routes>
          </AddressProvider>
      </ItemsProvider>
  );
}

function App() {
  const { auth } = useAuth();
  return (
    <div className="App">
        <Header />
        <main className="main-content">
            <Routes>
                <Route path="/" element={<Login />} />
                {auth.isAuthenticated && <Route path="/*" element={<ProtectedRoutes />} />}
            </Routes>
        </main>
        <Footer />
    </div>
  );
}

export default function RootApp() {
  return (
      <AuthProvider>
          <App />
      </AuthProvider>
  );
}